import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { TextField } from '@mui/material';
import { useAuth } from '@veripass/react-sdk';
import { InventoryItemService } from '@services';

async function updateEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.update(payload);

  return entityResponse;
}

const Container = styled.article`
  width: ${(props) => (props.$isPopup ? '800px' : '100%')};
  ${(props) => (props.$isPopup ? '' : 'flex-grow: 1;')};

  @media (max-width: 1199px) {
    width: ${(props) => (props.$isPopup ? '700px' : '100%')};
  }

  @media (max-width: 991px) {
    width: ${(props) => (props.$isPopup ? '600px' : '100%')};
  }

  @media (max-width: 767px) {
    width: ${(props) => (props.$isPopup ? '500px' : '100%')};
  }

  @media (max-width: 575px) {
    width: ${(props) => (props.$isPopup ? '100%' : '100%')};
  }
`;

const initialState = {
  certification_id: '',
  certifying_authority: '',
  issue_date: '',
  report_url: '',
  is_verified: false,
  comments: '',
};

function ItemLaboratoryReportUpdate({
  entity = null,
  onUpdatedEntity = () => {},
  isPopupContext = false,
}) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [entityData, setEntityData] = useState(
    entity?.properties?.laboratory_report ?? initialState,
  );

  const handleDataChange = (fieldName, data) => {
    setEntityData((prevItemData) => ({
      ...prevItemData,
      [fieldName]: data,
    }));
  };

  const getLogActions = () => {
    const originalEntity = entity?.properties?.laboratory_report || {};
    const modifiedFields = Object.keys(entityData).reduce(
      (acc, key) => {
        if (entityData[key] !== originalEntity[key]) {
          acc.updated[key] = entityData[key];
          acc.original[key] = originalEntity[key];
        }
        return acc;
      },
      { original: {}, updated: {} },
    );

    return {
      details: 'Laboratory report updated',
      metadata: modifiedFields,
      user: { id: user.identity || '', metadata: user.payload },
    };
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);

      const updatedEntity = {
        ...entity,
        properties: {
          ...entity.properties,
          laboratory_report: entityData,
        },
      };

      if (updatedEntity._id) {
        delete updatedEntity._id;
      }

      updatedEntity.log = getLogActions();

      const entityResponse = await updateEntity(updatedEntity, InventoryItemService);

      if (!entityResponse || !entityResponse.success) {
        onUpdatedEntity('update', null);
        return null;
      }

      setIsLoading(false);
      onUpdatedEntity('update', entityResponse.result);
    } catch (error) {
      setIsLoading(false);
      onUpdatedEntity('update', null);
    }
  };

  useEffect(() => {
    if (entity) {
      setEntityData(entity?.properties?.laboratory_report ?? initialState);
    }
  }, [entity]);

  return (
    <section>
      <Container $isPopup={isPopupContext} className={!isPopupContext ? 'col-12 my-2' : ''}>
        <form onSubmit={handleSubmit}>
          <article className="row">
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="certification-id-input"
                label="Certification ID"
                value={entityData.certification_id}
                placeholder="Enter certification ID"
                onChange={(event) => handleDataChange('certification_id', event.target.value)}
                autoComplete="off"
              />
            </section>
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="certifying-authority-input"
                label="Certifying Authority"
                value={entityData.certifying_authority}
                placeholder="Enter certifying authority"
                onChange={(event) => handleDataChange('certifying_authority', event.target.value)}
                autoComplete="off"
              />
            </section>
          </article>

          <article className="row">
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="issue-date-input"
                label="Issue Date"
                value={entityData.issue_date}
                placeholder="Enter issue date"
                onChange={(event) => handleDataChange('issue_date', event.target.value)}
                autoComplete="off"
              />
            </section>
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="report-url-input"
                label="Report URL"
                value={entityData.report_url}
                placeholder="Enter report URL"
                onChange={(event) => handleDataChange('report_url', event.target.value)}
                autoComplete="off"
              />
            </section>
          </article>

          <article className="row">
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="is-verified-input"
                label="Verified Status"
                value={entityData.is_verified ? 'Yes' : 'No'}
                placeholder="Enter verified status"
                onChange={(event) => handleDataChange('is_verified', event.target.value === 'Yes')}
                autoComplete="off"
              />
            </section>
            <section className="mb-3 col-12">
              <TextField
                className="w-100"
                id="comments-input"
                label="Comments"
                value={entityData.comments}
                placeholder="Enter comments"
                onChange={(event) => handleDataChange('comments', event.target.value)}
                autoComplete="off"
                multiline
                rows={4}
              />
            </section>
          </article>

          <article className="row">
            <section className="mb-0 h-25 d-flex justify-content-end align-items-end">
              {isLoading ? (
                <button type="button" disabled className="btn btn-dark">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Saving changes
                </button>
              ) : (
                <button type="submit" className="btn btn-dark waves-effect waves-light">
                  Save changes
                </button>
              )}
            </section>
          </article>
        </form>
      </Container>
    </section>
  );
}

export default ItemLaboratoryReportUpdate;
