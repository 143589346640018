import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';

import {
  AuthRecoverPasswordPage,
  AuthNewPasswordPage,
  AuthSigninPasswordPage,
  AuthLogoutPage,
  AuthLockscreenPage,

  AccountGettingStarted,
  AccountOverview,
  AccountProfile,

  DashboardAdmin,
  InsightHome,
  InsightFinance,
  InsightClients,
  InsightQuality,
  InsightService,
  InsightCondensed,
  MaintenanceGeneral,
  ItemDetailPage,
  ItemManagementPage,
  ItemManagementDailyPage,
  ItemQuickCreatePage,
  InventoryManagement,
  InventoryView,
  InventoryCreate,
} from './pages/index';

import { LayoutAdmin, LayoutAuth, LayoutMaintenance } from './layouts/index';
import { AuthProvider } from '@veripass/react-sdk';
import { Snackbar } from '@link-loom/react-sdk';

function App () {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Snackbar>
          <Routes>
            <Route path="/" element={<LayoutAuth />}>
              <Route index element={<Navigate to="/admin/dashboard" />} />
            </Route>
            <Route path="/auth" element={<LayoutAuth />}>
              <Route index element={<MaintenanceGeneral />} />
              <Route path="login" element={<AuthSigninPasswordPage />} />
              <Route path="logout" element={<AuthLogoutPage />} />
              <Route path="lock-screen" element={<AuthLockscreenPage />} />
              <Route path="recover-password" element={<AuthRecoverPasswordPage />} />
              <Route path="new-password" element={<AuthNewPasswordPage />} />
            </Route>
            <Route path="/admin" element={<LayoutAdmin />}>
              <Route index element={<Navigate to="/admin/dashboard" />} />
              <Route path="dashboard" element={<DashboardAdmin />} />
              <Route path="account">
                <Route index path="overview" element={<AccountOverview />} />
                <Route element={<AccountGettingStarted />} />
                <Route path="profile" element={<AccountProfile />} />
              </Route>
              <Route path="insight">
                <Route path="home" element={<InsightHome />} />
                <Route index element={<InsightCondensed />} />
                <Route path="clients" element={<InsightClients />} isPublic />
                <Route path="finance" element={<InsightFinance />} />
                <Route path="quality" element={<InsightQuality />} />
                <Route path="service" element={<InsightService />} />
              </Route>
              <Route path="inventory">
                <Route path="management" element={<InventoryManagement />} />
                <Route path="management/:id" element={<InventoryView />} />
                <Route path="create" element={<InventoryCreate />} />
              </Route>
              <Route path="item">
                <Route index element={<ItemManagementPage />} />
                <Route path="management" element={<ItemManagementPage />} />
                <Route path="management/daily" element={<ItemManagementDailyPage />} />
                <Route path="management/:id" element={<ItemDetailPage />} />
                <Route path="create" element={<ItemQuickCreatePage />} />
              </Route>
              <Route path="*" element={<MaintenanceGeneral />} />
            </Route>

            <Route path="*" element={<LayoutMaintenance />}>
              <Route path="*" element={<MaintenanceGeneral />} />
            </Route>
          </Routes>
        </Snackbar>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
