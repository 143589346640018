import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextField, IconButton, Button } from '@mui/material';
import { AddCircleOutline, Delete } from '@mui/icons-material';
import { useAuth } from '@veripass/react-sdk';
import { InventoryItemService } from '@services';

async function updateEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.update(payload);

  return entityResponse;
}

const Container = styled.article`
  width: ${(props) => (props.$isPopup ? '800px' : '100%')};
  ${(props) => (props.$isPopup ? '' : 'flex-grow: 1;')};
`;

const initialState = {
  references: [{ custom_id: 'reference-0', icon: '', text: '', link: '' }],
};

function ItemUrlReferencesUpdate({
  entity = null,
  onUpdatedEntity = () => {},
  isPopupContext = false,
}) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [entityData, setEntityData] = useState(entity?.properties?.references ?? initialState);

  const handleDataChange = (index, field, value) => {
    setEntityData((prevData) => {
      const updatedReferences = [...prevData];
      updatedReferences[index][field] = value;
      return updatedReferences;
    });
  };

  const addReference = () => {
    const randomId = `reference-${Math.floor(Math.random() * 1000000)}`;
    setEntityData((prevData) => [
      ...prevData,
      { custom_id: randomId, icon: '', text: '', link: '' },
    ]);
  };

  const deleteReference = (index) => {
    setEntityData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const getLogActions = () => {
    const originalEntity = entity?.properties?.references || [];
    const modifiedFields = {
      original: originalEntity,
      updated: entityData.references,
    };

    return {
      details: 'References updated',
      metadata: modifiedFields,
      user: { id: user.identity || '', metadata: user.payload },
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const updatedEntity = {
      ...entity,
      properties: {
        ...entity.properties,
        references: entityData,
      },
    };

    updatedEntity.log = getLogActions();

    const entityResponse = await updateEntity(updatedEntity, InventoryItemService);

    setIsLoading(false);
    onUpdatedEntity('update', entityResponse?.result || null);
  };

  useEffect(() => {
    if (entity) {
      setEntityData(entity?.properties?.references);
    }
  }, [entity]);

  return (
    <Container $isPopup={isPopupContext} className={!isPopupContext ? 'col-12 my-2' : ''}>
      <form onSubmit={handleSubmit}>
        {entityData?.map((reference, index) => (
          <article key={reference?.custom_id} className="row mb-3">
            <section className="col-3">
              <TextField
                className="w-100"
                label="Icon"
                value={reference?.icon}
                onChange={(e) => handleDataChange(index, 'icon', e.target.value)}
                placeholder="Enter icon name"
                autoComplete="off"
              />
            </section>
            <section className="col-4">
              <TextField
                className="w-100"
                label="Link"
                value={reference?.link}
                onChange={(e) => handleDataChange(index, 'link', e.target.value)}
                placeholder="Enter link URL"
                autoComplete="off"
              />
            </section>
            <section className="col-4">
              <TextField
                className="w-100"
                label="Text"
                value={reference?.text}
                onChange={(e) => handleDataChange(index, 'text', e.target.value)}
                placeholder="Enter description"
                autoComplete="off"
              />
            </section>
            <section className="col-1 d-flex align-items-center">
              <IconButton onClick={() => deleteReference(index)} color="error">
                <Delete />
              </IconButton>
            </section>
          </article>
        ))}

        <Button
          variant="outlined"
          color="primary"
          startIcon={<AddCircleOutline />}
          onClick={addReference}
        >
          Add Reference
        </Button>

        <article className="row mt-4">
          <section className="col d-flex justify-content-end">
            {isLoading ? (
              <Button variant="contained" color="primary" disabled>
                Saving changes...
              </Button>
            ) : (
              <Button variant="contained" color="primary" type="submit">
                Save Changes
              </Button>
            )}
          </section>
        </article>
      </form>
    </Container>
  );
}

export default ItemUrlReferencesUpdate;
