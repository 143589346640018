import BaseApi from '../base/api.service';

export default class UploadService extends BaseApi {
  constructor(args) {
    super(args);

    this.serviceEndpoints = {
      baseUrl: process.env.REACT_APP_BACKEND_URL,
      post: '/file/upload/photo',
    };
  }

  async post(data) {
    return super.post(data);
  }
}
