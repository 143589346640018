import BaseApi from '../base/api.service';

export default class UserService extends BaseApi {
  constructor(args) {
    super(args);

    this.serviceEndpoints = {
      baseUrl: process.env.REACT_APP_SERVICE_VERIPASS_URL,
      get: '/identity/user/',
      create: '/identity/user',
      update: '/identity/user',
      delete: '/identity/user'
    };
  }

  async getByParameters(data) {
    return super.getByParameters(data);
  }

  async update(data) {
    return super.update(data);
  }

  async create(data) {
    return super.create(data);
  }

  async delete(data){
    return super.delete(data);
  }
}
