import React, { useEffect, useState } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { Alert, OnPageLoaded, useSnackbar } from '@link-loom/react-sdk';

import { ItemQuickCreateGemstone } from '@components/pages';

function ItemQuickCreatePage() {
  const { setPageName } = useOutletContext();
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(null);
  const [isConfirmed, setIsConfirmed] = useState(null);
  const [alertConfigs, setAlertConfigs] = useState(null);
  const [entitySelected, setEntitySelected] = useState(null);

  const itemOnCreated = (response) => {
    debugger;
    if (!response || !response.success) {
      setAlertConfigs({
        title: 'Error',
        description: 'Something was wrong while creating item.',
        typeIcon: 'error',
        confirmButtonText: 'Exit',
        cancelButtonText: 'Try again',
        cancelButtonClass: 'btn btn-white',
        showCancelButton: true,
        currentAction: 'created',
        isError: true,
      });
      setShowAlert(true);
      return;
    }

    const entity = response.result ?? {};

    setEntitySelected(entity);
    setAlertConfigs({
      title: 'Success',
      description: 'Item created successfully, you want to complete profile?',
      typeIcon: 'success',
      confirmButtonText: 'Complete profile',
      cancelButtonText: 'Cancel',
      cancelButtonClass: 'btn btn-white',
      showCancelButton: true,
      currentAction: 'created',
    });
    setShowAlert(true);
    openSnackbar('Item created successfully', 'success');
  };

  const onUpdatedEntity = (action, response) => {
    switch (action) {
      case 'create':
        itemOnCreated(response);
        break;
    }
  };

  const alertOnConfirmed = (isConfirmed) => {
    setIsConfirmed(isConfirmed);

    if (alertConfigs.isError) {
      return;
    }

    switch (alertConfigs.currentAction) {
      case 'created':
        navigate(`/admin/item/management/${entitySelected?.id}`);
        break;

      default:
        break;
    }

    setShowAlert(false);
  };

  useEffect(() => {
    if (isConfirmed === false) {
      setShowAlert(null);
      setIsConfirmed(null);
    }
  }, [isConfirmed, showAlert]);

  useEffect(() => {
    setPageName('Item management');
  }, []);

  return (
    <>
      <ItemQuickCreateGemstone onUpdatedEntity={onUpdatedEntity} />
      {showAlert === true && <Alert config={alertConfigs} setConfirm={alertOnConfirmed} />}
      <OnPageLoaded />
    </>
  );
}

export default ItemQuickCreatePage;
