import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { InventoryManagementService } from '@services/index';
import { Alert, Placeholder } from '@link-loom/react-sdk';

const Loader = () => (
  <Placeholder type="table" count={10}>
    <Placeholder type="title" count={1}>
      <Placeholder type="description" count={1} />
    </Placeholder>
  </Placeholder>
);

function InventoryManagement() {
  const { setPageName } = useOutletContext();
  const [entities, setEntities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [statuses, setStatuses] = useState({});
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isConfirm, setIsConfirm] = useState(null);
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  useEffect(() => {
    setPageName('Inventories');

    const fetchData = async () => {
      const entityService = new InventoryManagementService();
      const entityPromise = entityService.getByParameters({
        queryselector: 'all',
        search: '',
      });
      const entityStatusesPromise = entityService.getByParameters({
        queryselector: 'status',
        search: '',
      });

      try {
        const [entityResponse, entityStatuses] = await Promise.all([
          entityPromise,
          entityStatusesPromise,
        ]);

        if (
          !entityResponse ||
          !entityResponse.result.items ||
          !entityResponse.result.items.length ||
          !entityResponse.success
        ) {
          setIsAlertVisible(true);
        }

        if (entityResponse && entityStatuses && !isAlertVisible) {
          setEntities(entityResponse.result?.items || []);
          setStatuses(entityStatuses.result || {});
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData().catch(console.error);
  }, []);

  useEffect(() => {
    if (isConfirm === true) {
      if (alertInfo.type === 'confirm delete') {
        handleConfirmOperation('delete');
      } else {
        handleConfirmOperation();
      }
    }
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  const handleOperationError = () => {
    setAlertInfo({
      message: 'Something went wrong. Please try again.',
      type: 'error',
    });
  };

  const handleConfirmOperation = async (operationType) => {
    try {
      const { id, newStatus } = alertInfo;
      const entityService = new InventoryManagementService();
      let entityResponse;

      if (operationType === 'delete') {
        entityResponse = await entityService.delete({ id });
      } else {
        entityResponse = await entityService.update({ id, status: newStatus });
      }

      if (entityResponse) {
        const updatedEntities = entities.map((entity) => {
          if (entity.id === id) {
            return {
              ...entity,
              status: newStatus,
            };
          }
          return entity;
        });

        setEntities(updatedEntities);
        setAlertInfo({
          message: 'Operation completed successfully!',
          type: 'success',
        });
      } else {
        handleOperationError();
      }
    } catch (error) {
      console.error(error);
      handleOperationError();
    }
  };

  const handleArchive = async (id, newStatus) => {
    setAlertInfo({
      message: `Are you sure you want to inactive this organization?`,
      type: 'confirm archive',
      id: id,
      newStatus: newStatus,
    });
  };

  const handleDelete = async (id, newStatus) => {
    setAlertInfo({
      message: `Are you sure you want to delete this organization?`,
      type: 'confirm delete',
      id: id,
      newStatus: newStatus,
    });
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'confirm archive':
        return {
          title: 'Confirm Action',
          description: alertInfo.message,
          typeIcon: 'question',
          confirmButtonText: 'Inactive',
          cancelButtonText: 'Cancel',
          showCancelButton: true,
        };
      case 'confirm delete':
        return {
          title: 'Confirm Action',
          description: alertInfo.message,
          typeIcon: 'question',
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          showCancelButton: true,
        };
      case 'success':
        return {
          title: 'Success',
          description: alertInfo.message,
          typeIcon: 'success',
          showCancelButton: false,
        };
      case 'error':
        return {
          title: 'Error',
          description: alertInfo.message,
          typeIcon: 'error',
          showCancelButton: false,
        };
      default:
        return null;
    }
  };

  return (
    <article className="card shadow">
      {alertInfo.type && <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />}
      {isLoading ? (
        <Loader />
      ) : (
        <section className="card-body">
          <header>
            <section className="dropdown float-end">
              <a href="create" className="btn btn-soft-success">
                <i className="mdi mdi-plus me-1"></i>Add new
              </a>
            </section>
            <h4 className="mt-0 header-title">Management</h4>
            <p className="text-muted font-14 mb-3">
              Here you can see, archive and delete organizations
            </p>
          </header>
          {isAlertVisible ? (
            <div className="alert alert-danger bg-secondary text-white border-0 w-50 d-flex justify-content-center mx-auto">
              <span className="mx-auto">
                No data <strong>available</strong> at the moment!
              </span>
            </div>
          ) : (
            <section className="table-responsive">
              <table className="table table-hover mb-0">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Status</th>
                    <th>Name</th>
                    <th>Logo URL</th>
                    <th>Slug</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(entities) &&
                    entities.length &&
                    entities.map((organization, key) => (
                      <tr key={key}>
                        <th scope="row">{organization.id}</th>
                        <td>{organization.status.title}</td>
                        <td>{organization.display_name}</td>
                        <td>{organization.logo_url}</td>
                        <td>{organization.slug}</td>
                        <td>
                          <div className="dropdown">
                            <a
                              href="#"
                              className="dropdown-toggle arrow-none card-drop"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="mdi mdi-dots-vertical"></i>
                            </a>
                            <div className="dropdown-menu dropdown-menu-end">
                              <a
                                href={`/admin/organization/management/${organization.id}`}
                                className="dropdown-item"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="mdi mdi-open-in-new"></i> View detail
                              </a>
                              <button
                                onClick={(e) => handleArchive(organization.id, statuses.inactive)}
                                className="dropdown-item"
                              >
                                <i className="mdi mdi-archive-outline"></i> Archive
                              </button>
                              <button
                                onClick={(e) => handleDelete(organization.id, statuses.deleted)}
                                className="dropdown-item"
                              >
                                <i className="mdi mdi-trash-can-outline"></i> Delete
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </section>
          )}
        </section>
      )}
    </article>
  );
}

export default InventoryManagement;
