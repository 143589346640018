import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { TextField } from '@mui/material';
import { useAuth } from '@veripass/react-sdk';
import { InventoryItemService } from '@services';

async function updateEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.update(payload);

  return entityResponse;
}

const Container = styled.article`
  width: ${(props) => (props.$isPopup ? '800px' : '100%')};
  ${(props) => (props.$isPopup ? '' : 'flex-grow: 1;')};

  @media (max-width: 1199px) {
    width: ${(props) => (props.$isPopup ? '700px' : '100%')};
  }

  @media (max-width: 991px) {
    width: ${(props) => (props.$isPopup ? '600px' : '100%')};
  }

  @media (max-width: 767px) {
    width: ${(props) => (props.$isPopup ? '500px' : '100%')};
  }

  @media (max-width: 575px) {
    width: ${(props) => (props.$isPopup ? '100%' : '100%')};
  }
`;

const initialState = {
  height: '',
  width: '',
  depth: '',
  trade_measure: '',
  girdle: '',
  table: '',
  crown: '',
  pavilion_depth: '',
  pavilion_average_angle: '',
  unit: '',
};

function ItemSizeUpdate({ entity = null, onUpdatedEntity = () => {}, isPopupContext = false }) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [entityData, setEntityData] = useState(entity?.properties?.size ?? initialState);

  const handleDataChange = (fieldName, data) => {
    setEntityData((prevItemData) => ({
      ...prevItemData,
      [fieldName]: data,
    }));
  };

  const getLogActions = () => {
    // Detect changes in color properties
    const originalEntity = entity?.properties?.size || {};
    const modifiedFields = Object.keys(entityData).reduce(
      (acc, key) => {
        if (entityData[key] !== originalEntity[key]) {
          acc.updated[key] = entityData[key];
          acc.original[key] = originalEntity[key];
        }
        return acc;
      },
      { original: {}, updated: {} },
    );

    return {
      details: 'Size updated',
      metadata: modifiedFields,
      user: { id: user.identity || '', metadata: user.payload },
    };
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);

      // Setup random ui settings
      const updatedEntity = {
        ...entity,
        properties: {
          ...entity.properties,
          size: entityData,
        },
      };

      if (updatedEntity._id) {
        delete updatedEntity._id;
      }

      updatedEntity.log = getLogActions();

      const entityResponse = await updateEntity(updatedEntity, InventoryItemService);

      if (!entityResponse || !entityResponse.success) {
        onUpdatedEntity('update', null);
        return null;
      }

      // Update parent states
      setIsLoading(false);
      onUpdatedEntity('update', entityResponse.result);
    } catch (error) {
      setIsLoading(false);
      onUpdatedEntity('update', null);
    }
  };

  useEffect(() => {
    if (entity) {
      setEntityData(entity?.properties?.size ?? initialState);
    }
  }, [entity]);

  return (
    <section>
      <Container $isPopup={isPopupContext} className={!isPopupContext ? 'col-12 my-2' : ''}>
        <form onSubmit={handleSubmit}>
          <article className="row">
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="height-input"
                label="Height"
                value={entityData.height}
                placeholder="Enter height"
                onChange={(event) => {
                  handleDataChange('height', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="width-input"
                label="Width"
                value={entityData.width}
                placeholder="Enter width"
                onChange={(event) => {
                  handleDataChange('width', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
          </article>

          <article className="row">
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="depth-input"
                label="Depth"
                value={entityData.depth}
                placeholder="Enter depth"
                onChange={(event) => {
                  handleDataChange('depth', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="trade-measure-input"
                label="Trade Measure"
                value={entityData.trade_measure}
                placeholder="Enter trade measure"
                onChange={(event) => {
                  handleDataChange('trade_measure', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
          </article>

          <article className="row">
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="girdle-input"
                label="Girdle"
                value={entityData.girdle}
                placeholder="Enter girdle"
                onChange={(event) => {
                  handleDataChange('girdle', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="table-input"
                label="Table"
                value={entityData.table}
                placeholder="Enter table"
                onChange={(event) => {
                  handleDataChange('table', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
          </article>

          <article className="row">
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="crown-input"
                label="Crown"
                value={entityData.crown}
                placeholder="Enter crown"
                onChange={(event) => {
                  handleDataChange('crown', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="pavilion-depth-input"
                label="Pavilion Depth"
                value={entityData.pavilion_depth}
                placeholder="Enter pavilion depth"
                onChange={(event) => {
                  handleDataChange('pavilion_depth', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
          </article>

          <article className="row">
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="pavilion-average-angle-input"
                label="Pavilion Average Angle"
                value={entityData.pavilion_average_angle}
                placeholder="Enter pavilion average angle"
                onChange={(event) => {
                  handleDataChange('pavilion_average_angle', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
            <section className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                id="unit-input"
                label="Unit"
                value={entityData.unit}
                placeholder="Enter unit"
                onChange={(event) => {
                  handleDataChange('unit', event.target.value);
                }}
                autoComplete="off"
              />
            </section>
          </article>

          <article className="row">
            <section className="mb-0 h-25 d-flex justify-content-end align-items-end">
              {isLoading ? (
                <button type="button" disabled className="btn btn-dark">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Saving changes
                </button>
              ) : (
                <button type="submit" className="btn btn-dark waves-effect waves-light">
                  Save changes
                </button>
              )}
            </section>
          </article>
        </form>
      </Container>
    </section>
  );
}

export default ItemSizeUpdate;
