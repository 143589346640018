import React, { useState, useEffect } from 'react';

import { FormGroup, FormControlLabel, Switch } from '@mui/material';
import { Alert, Spinner } from '@link-loom/react-sdk';

import { ItemSizePreview, ItemSizeUpdate } from '@components/pages';

const defaultAlertConfigs = {
  success: {
    title: 'Success',
    description: 'Operation completed successfully, do you want to continue editing?',
    typeIcon: 'success',
    confirmButtonText: 'Yes, continue editing',
    cancelButtonText: 'No',
    cancelButtonClass: 'btn btn-white',
    showCancelButton: true,
    currentAction: 'updated',
  },
  error: {
    title: 'Error',
    description: 'Something was wrong while executing action.',
    typeIcon: 'error',
    confirmButtonText: 'Exit',
    cancelButtonText: 'Try again',
    cancelButtonClass: 'btn btn-white',
    showCancelButton: true,
    currentAction: 'updated',
  },
};

const ItemSizeDetail = (props) => {
  const { entityState, entityRef, relatedEntities, loadingState } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const [showDialog, setShowDialog] = useState(null);
  const [dialogConfigs, setDialogConfigs] = useState(null);

  const handleEditChange = () => {
    setIsEditMode((prevEditMode) => !prevEditMode);
  };

  const dialogOnConfirmed = (isConfirmed) => {
    switch (dialogConfigs.currentAction) {
      case 'updated':
        setIsEditMode(isConfirmed);
        break;

      default:
        break;
    }

    setShowDialog(false);
  };

  const onUpdatedEntity = (action, updatedEntity) => {
    if (!updatedEntity) {
      setDialogConfigs(defaultAlertConfigs.error);
      setShowDialog(true);
      return;
    }

    switch (action) {
      case 'update':
        entityState.setEntity(updatedEntity);
        setIsEditMode(false);
        setDialogConfigs(defaultAlertConfigs.success);
        setShowDialog(true);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (entityState === false) {
      loadingState.setIsLoading(false);
    }
  }, [entityState]);

  return (
    <>
      <header className="d-flex flex-row justify-content-end">
        <section className="d-flex align-items-sm-baseline">
          <FormGroup>
            <FormControlLabel
              control={<Switch checked={isEditMode} onChange={handleEditChange} name="editmode" />}
              label="Edit mode"
            />
          </FormGroup>
        </section>
      </header>

      <section className="content">
        {loadingState.isLoading ? (
          <Spinner />
        ) : (
          <>
            {isEditMode ? (
              <ItemSizeUpdate entity={entityState?.entity} onUpdatedEntity={onUpdatedEntity} />
            ) : (
              <ItemSizePreview entity={entityState?.entity?.properties?.size} />
            )}
          </>
        )}
      </section>

      {showDialog === true && <Alert config={dialogConfigs} setConfirm={dialogOnConfirmed} />}
    </>
  );
};

export default ItemSizeDetail;
