import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

function InsightHome() {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Insights');
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 col-xl-3">
          <article className="card shadow">
            <img
              className="card-img-top img-fluid"
              src="/assets/images/side-pages/insights-clients.jpg"
              alt="Card image cap"
            />
            <div className="card-body text-center">
              <a
                href="/admin/insight/clients"
                className="btn btn-bordered-primary"
              >
                Clientes
              </a>
            </div>
          </article>
        </div>

        <div className="col-md-4 col-xl-3">
          <article className="card shadow">
            <img
              className="card-img-top img-fluid"
              src="/assets/images/side-pages/insights-finance.jpg"
              alt="Card image cap"
            />
            <div className="card-body text-center">
              <a
                href="/admin/insight/finance"
                className="btn btn-bordered-primary"
              >
                Financiero
              </a>
            </div>
          </article>
        </div>

        <div className="col-md-4 col-xl-3">
          <article className="card shadow">
            <img
              className="card-img-top img-fluid"
              src="/assets/images/side-pages/insights-quality.jpg"
              alt="Card image cap"
            />
            <div className="card-body text-center">
              <a
                href="/admin/insight/quality"
                className="btn btn-bordered-primary"
              >
                Calidad
              </a>
            </div>
          </article>
        </div>

        <div className="col-md-4 col-xl-3">
          <article className="card shadow">
            <img
              className="card-img-top img-fluid"
              src="/assets/images/side-pages/insights-service.jpg"
              alt="Card image cap"
            />
            <div className="card-body text-center">
              <a
                href="/admin/insight/service"
                className="btn btn-bordered-primary"
              >
                Servicio
              </a>
            </div>
          </article>
        </div>
      </div>
    </div>
  );
}

export default InsightHome;
