import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import { useAuth } from '@veripass/react-sdk';
import { InventoryItemService } from '@services';

async function updateEntity(payload, entity) {
  const entityService = new entity();
  const entityResponse = await entityService.update(payload);

  return entityResponse;
}

const Container = styled.article`
  width: ${(props) => (props.$isPopup ? '800px' : '100%')};
  ${(props) => (props.$isPopup ? '' : 'flex-grow: 1;')};

  @media (max-width: 1199px) {
    width: ${(props) => (props.$isPopup ? '700px' : '100%')};
  }

  @media (max-width: 991px) {
    width: ${(props) => (props.$isPopup ? '600px' : '100%')};
  }

  @media (max-width: 767px) {
    width: ${(props) => (props.$isPopup ? '500px' : '100%')};
  }

  @media (max-width: 575px) {
    width: ${(props) => (props.$isPopup ? '100%' : '100%')};
  }
`;

const initialState = {
  family: '',
  species: '',
  variety: '',
  subvariety: '',
  chemical_formula: '',
  origin: '',
  treatments: '',
  hardness_mohs_scale: '',
  refractive_index: '',
  specific_gravity: '',
  inclusions: '',
  dispersion: '',
  birefringence: '',
  is_lab_grown: false,
  fluorescence: '',
  optical_effects: '',
  luster: '',
  phenomena: '',
  crystal_system: '',
  tenacity: '',
  fracture: '',
  cleavage: '',
  pleochroism: '',
  color_zoning: '',
  thermal_conductivity: '',
  phosphorescence: '',
  durability: '',
  specific_inclusions: '',
  double_refraction_index: '',
};

const textFields = [
  'family',
  'species',
  'variety',
  'subvariety',
  'chemical_formula',
  'origin',
  'treatments',
  'hardness_mohs_scale',
  'refractive_index',
  'specific_gravity',
  'inclusions',
  'dispersion',
  'birefringence',
];

const OPTIONS = {
  crystal_system: [
    { id: 1, name: 'trigonal', title: 'Trigonal' },
    { id: 2, name: 'cubic', title: 'Cubic' },
    { id: 3, name: 'tetragonal', title: 'Tetragonal' },
    { id: 4, name: 'hexagonal', title: 'Hexagonal' },
    { id: 999, name: 'not_applicable', title: 'Not Applicable' },
  ],
  tenacity: [
    { id: 1, name: 'fragile', title: 'Fragile' },
    { id: 2, name: 'tough', title: 'Tough' },
    { id: 3, name: 'brittle', title: 'Brittle' },
    { id: 999, name: 'not_applicable', title: 'Not Applicable' },
  ],
  fracture: [
    { id: 1, name: 'concoidal', title: 'Conchoidal' },
    { id: 2, name: 'fibrous', title: 'Fibrous' },
    { id: 3, name: 'uneven', title: 'Uneven' },
    { id: 4, name: 'no_fracture', title: 'No Fracture' },
    { id: 999, name: 'not_applicable', title: 'Not Applicable' },
  ],
  cleavage: [
    { id: 1, name: 'perfect', title: 'Perfect' },
    { id: 2, name: 'good', title: 'Good' },
    { id: 3, name: 'poor', title: 'Poor' },
    { id: 999, name: 'not_applicable', title: 'Not Applicable' },
  ],
  pleochroism: [
    { id: 1, name: 'strong', title: 'Strong' },
    { id: 2, name: 'moderate', title: 'Moderate' },
    { id: 3, name: 'weak', title: 'Weak' },
    { id: 999, name: 'not_applicable', title: 'Not Applicable' },
  ],
  color_zoning: [
    { id: 1, name: 'visible', title: 'Visible' },
    { id: 2, name: 'not_visible', title: 'Not Visible' },
    { id: 999, name: 'not_applicable', title: 'Not Applicable' },
  ],
  thermal_conductivity: [
    { id: 1, name: 'high', title: 'High' },
    { id: 2, name: 'medium', title: 'Medium' },
    { id: 3, name: 'low', title: 'Low' },
    { id: 999, name: 'not_applicable', title: 'Not Applicable' },
  ],
  phosphorescence: [
    { id: 1, name: 'present', title: 'Present' },
    { id: 2, name: 'absent', title: 'Absent' },
    { id: 999, name: 'not_applicable', title: 'Not Applicable' },
  ],
  durability: [
    { id: 1, name: 'high', title: 'High' },
    { id: 2, name: 'medium', title: 'Medium' },
    { id: 3, name: 'low', title: 'Low' },
    { id: 999, name: 'not_applicable', title: 'Not Applicable' },
  ],
  specific_inclusions: [
    { id: 1, name: 'rutilated_needles', title: 'Rutilated Needles' },
    { id: 2, name: 'gas_bubbles', title: 'Gas Bubbles' },
    { id: 3, name: 'none', title: 'None' },
    { id: 999, name: 'not_applicable', title: 'Not Applicable' },
  ],
  double_refraction_index: [
    { id: 1, name: 'high', title: 'High' },
    { id: 2, name: 'medium', title: 'Medium' },
    { id: 3, name: 'low', title: 'Low' },
    { id: 999, name: 'not_applicable', title: 'Not Applicable' },
  ],
  fluorescence: [
    { id: 1, name: 'none', title: 'None' },
    { id: 2, name: 'faint', title: 'Faint' },
    { id: 3, name: 'medium', title: 'Medium' },
    { id: 4, name: 'strong', title: 'Strong' },
    { id: 5, name: 'very_strong', title: 'Very Strong' },
    { id: 999, name: 'not_applicable', title: 'Not Applicable' },
  ],
  optical_effects: [
    { id: 1, name: 'asterism', title: 'Asterism' },
    { id: 2, name: 'chatoyancy', title: 'Chatoyancy' },
    { id: 3, name: 'adularescence', title: 'Adularescence' },
    { id: 4, name: 'iridescence', title: 'Iridescence' },
    { id: 5, name: 'none', title: 'None' },
    { id: 999, name: 'not_applicable', title: 'Not Applicable' },
  ],
  luster: [
    { id: 1, name: 'vitreous', title: 'Vitreous' },
    { id: 2, name: 'adamantine', title: 'Adamantine' },
    { id: 3, name: 'silky', title: 'Silky' },
    { id: 4, name: 'metallic', title: 'Metallic' },
    { id: 5, name: 'resinous', title: 'Resinous' },
    { id: 6, name: 'pearly', title: 'Pearly' },
    { id: 7, name: 'greasy', title: 'Greasy' },
    { id: 8, name: 'dull', title: 'Dull' },
    { id: 999, name: 'not_applicable', title: 'Not Applicable' },
  ],
  phenomena: [
    { id: 1, name: 'color_change', title: 'Color Change' },
    { id: 2, name: 'opalescence', title: 'Opalescence' },
    { id: 3, name: 'play_of_color', title: 'Play of Color' },
    { id: 4, name: 'labradorescence', title: 'Labradorescence' },
    { id: 5, name: 'none', title: 'None' },
    { id: 999, name: 'not_applicable', title: 'Not Applicable' },
  ],
};

function ItemGemologicalCompositionUpdate({
  entity = null,
  onUpdatedEntity = () => {},
  isPopupContext = false,
}) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [entityData, setEntityData] = useState(
    entity?.properties?.gemological_composition ?? initialState,
  );

  const handleDataChange = (fieldName, data) => {
    const updatedData = OPTIONS[fieldName]
      ? OPTIONS[fieldName].find((option) => option.name === data)
      : data;

    setEntityData((prevItemData) => ({
      ...prevItemData,
      [fieldName]: updatedData,
    }));
  };

  const getLogActions = () => {
    const originalEntity = entity?.properties?.laboratory_report || {};
    const modifiedFields = Object.keys(entityData).reduce(
      (acc, key) => {
        if (entityData[key] !== originalEntity[key]) {
          acc.updated[key] = entityData[key];
          acc.original[key] = originalEntity[key];
        }
        return acc;
      },
      { original: {}, updated: {} },
    );

    return {
      details: 'Gemological composition updated',
      metadata: modifiedFields,
      user: { id: user.identity || '', metadata: user.payload },
    };
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);

      const updatedEntity = {
        ...entity,
        properties: {
          ...entity.properties,
          gemological_composition: entityData,
        },
      };

      if (updatedEntity._id) {
        delete updatedEntity._id;
      }

      updatedEntity.log = getLogActions();

      const entityResponse = await updateEntity(updatedEntity, InventoryItemService);

      if (!entityResponse || !entityResponse.success) {
        onUpdatedEntity('update', null);
        return null;
      }

      setIsLoading(false);
      onUpdatedEntity('update', entityResponse.result);
    } catch (error) {
      setIsLoading(false);
      onUpdatedEntity('update', null);
    }
  };

  useEffect(() => {
    if (entity) {
      setEntityData(entity?.properties?.gemological_composition ?? initialState);
    }
  }, [entity]);

  return (
    <Container $isPopup={isPopupContext} className={!isPopupContext ? 'col-12 my-2' : ''}>
      <form onSubmit={handleSubmit}>
        <article className="row">
          {textFields.map((field) => (
            <section key={field} className="mb-3 col-12 col-md-6">
              <TextField
                className="w-100"
                label={field.replace('_', ' ')}
                value={entityData[field]}
                onChange={(event) => handleDataChange(field, event.target.value)}
              />
            </section>
          ))}
        </article>

        <article className="row">
          {Object.keys(OPTIONS).map((key) => (
            <section key={key} className="mb-3 col-12 col-md-6">
              <FormControl className="w-100">
                <InputLabel>{key.replace('_', ' ')}</InputLabel>
                <Select
                  value={entityData[key]?.name || ''}
                  onChange={(event) => handleDataChange(key, event.target.value)}
                >
                  {OPTIONS[key].map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </section>
          ))}
        </article>

        <article className="row">
          <section className="mb-3 col-12">
            <FormControlLabel
              control={
                <Checkbox
                  checked={entityData.is_lab_grown}
                  onChange={(event) => handleDataChange('is_lab_grown', event.target.checked)}
                />
              }
              label="Is Lab Grown"
            />
          </section>
        </article>

        <article className="row">
          <section className="mb-0 h-25 d-flex justify-content-end align-items-end">
            {isLoading ? (
              <button type="button" disabled className="btn btn-dark">
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Saving changes
              </button>
            ) : (
              <button type="submit" className="btn btn-dark waves-effect waves-light">
                Save changes
              </button>
            )}
          </section>
        </article>
      </form>
    </Container>
  );
}

export default ItemGemologicalCompositionUpdate;
