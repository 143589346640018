import React, { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { OnPageLoaded } from '@link-loom/react-sdk';

import { ItemListDaily } from '@components/pages';

function ItemManagementDailyPage() {
  const { setPageName } = useOutletContext();

  useEffect(() => {
    setPageName('Inventory Items management');
  }, []);

  return (
    <>
      <ItemListDaily />
      <OnPageLoaded />
    </>
  );
}

export default ItemManagementDailyPage;
