import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Card, CardMedia, Menu, MenuItem, Chip, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { useAuth } from '@veripass/react-sdk';
import { Uploader } from '@link-loom/react-sdk';
import { InventoryItemService, UploadService } from '@services';

async function updateEntity({ Service, payload }) {
  const entityService = new Service();
  const entityResponse = await entityService.update(payload);

  return entityResponse;
}

async function createEntity({ Service, payload }) {
  const entityService = new Service();
  const entityResponse = await entityService.post(payload);

  if (!entityResponse || !entityResponse.result) {
    console.error(entityResponse);
    return null;
  }

  return entityResponse;
}

const Container = styled.article`
  width: ${(props) => (props.$isPopup ? '800px' : '100%')};
  ${(props) => (props.$isPopup ? '' : 'flex-grow: 1;')};

  @media (max-width: 1199px) {
    width: ${(props) => (props.$isPopup ? '700px' : '100%')};
  }

  @media (max-width: 991px) {
    width: ${(props) => (props.$isPopup ? '600px' : '100%')};
  }

  @media (max-width: 767px) {
    width: ${(props) => (props.$isPopup ? '500px' : '100%')};
  }

  @media (max-width: 575px) {
    width: ${(props) => (props.$isPopup ? '100%' : '100%')};
  }
`;

const initialState = [];

function ItemMediaUpdate({ entity = null, onUpdatedEntity = () => {}, isPopupContext = false }) {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [entityData, setEntityData] = useState(initialState);

  // UI States
  const [mediaUploadPreview, setMediaUploadPreview] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState(null);

  const handleMenuOpen = (event, media) => {
    setAnchorEl(event.currentTarget);
    setSelectedMedia(media);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedMedia(null);
  };

  const handleMakePrimary = () => {
    if (selectedMedia && !selectedMedia.is_primary) {
      // Lógica para actualizar el elemento como primary
    }
    handleMenuClose();
  };

  const handleDelete = () => {
    // Lógica para eliminar el elemento
    handleMenuClose();
  };

  const handleDataChange = (fieldName, data) => {
    setEntityData((prevItemData) => ({
      ...prevItemData,
      [fieldName]: data,
    }));
  };

  const getLogActions = () => {
    // Detect changes in color properties
    const originalEntity = entity?.properties?.media || {};
    const modifiedFields = Object.keys(entityData).reduce(
      (acc, key) => {
        if (entityData[key] !== originalEntity[key]) {
          acc.updated[key] = entityData[key];
          acc.original[key] = originalEntity[key];
        }
        return acc;
      },
      { original: {}, updated: {} },
    );

    return {
      details: 'Media updated',
      metadata: modifiedFields,
      user: { id: user.identity || '', metadata: user.payload },
    };
  };

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);

      // Setup random ui settings
      var updatedEntity = entityData;

      if (updatedEntity._id) {
        delete updatedEntity._id;
      }

      updatedEntity.log = getLogActions();

      const entityResponse = await updateEntity({
        payload: updatedEntity,
        Service: InventoryItemService,
      });

      if (!entityResponse || !entityResponse.success) {
        onUpdatedEntity('update', null);
        return null;
      }

      // Update parent states
      setIsLoading(false);
      onUpdatedEntity('update', entityResponse.result);
    } catch (error) {
      setIsLoading(false);
      onUpdatedEntity('update', null);
    }
  };

  const handleUploadFile = async (fileData) => {
    try {
      setIsLoading(true);

      if (!fileData) {
        return;
      }

      fileData?.payload?.append('id', entity?.id);

      console.log('entity.id', entity?.id);

      const fileUploadedResponse = await createEntity({
        payload: fileData.payload,
        Service: UploadService,
      });

      setIsLoading(false);

      if (!fileUploadedResponse || !fileUploadedResponse.success) {
        console.error(fileUploadedResponse);
        onUpdatedEntity('error', null);
        return null;
      }

      // return data to control
      return fileUploadedResponse;
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      onUpdatedEntity('error', null);
    }
  };

  const uploaderOnEvent = ({ event, data }) => {
    debugger;
    switch (event) {
      case 'onFileLoaded':
        setMediaUploadPreview(data?.file);
        break;
      case 'delete':
        setMediaUploadPreview(null);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (entity) {
      console.log('entity:', entity?.media);
      setEntityData(entity?.media);
    }
  }, [entity]);

  return (
    <section>
      <Container $isPopup={isPopupContext} className={!isPopupContext ? 'col-12 my-2' : ''}>
        <form onSubmit={handleSubmit}>
          <section className="row d-flex mb-4">
            <article className="mx-auto col-12 col-md-6 col-lg-4">
              <Uploader
                upload={handleUploadFile}
                file={mediaUploadPreview}
                behaviors={{
                  isInline: true,
                  isMultipleFile: false,
                }}
                accept="image/x-png, image/gif, image/jpeg, image/webp, image/svg+xml, image/bmp, image/tiff, .mov, .mp4, video/mp4, video/quicktime"
                height="200px"
                folder="sdfsdf"
                onEvent={(event, data) => {
                  debugger;
                  uploaderOnEvent({ event, data });
                }}
                actions={[
                  {
                    title: 'Copiar',
                    action: 'copy',
                    disabled: false,
                  },
                  {
                    title: 'Ver',
                    action: 'view',
                    disabled: false,
                  },
                  {
                    title: 'Eliminar',
                    action: 'delete',
                    disabled: false,
                  },
                ]}
                componentTexts={{
                  fileSize: '5Mb',
                }}
              />
            </article>
          </section>
          <section className="row">
            {entityData?.map((media, index) => (
              <article className="col-3 mb-4" key={index}>
                <Card className="w-100 d-flex flex-column" sx={{ position: 'relative' }}>
                  <div className="d-flex justify-content-between align-items-center p-2 w-100">
                    <div>
                      {media.is_primary && (
                        <Chip
                          label="Search Cover"
                          variant="outlined"
                          className="me-1"
                          sx={{
                            top: 8,
                            left: 8,
                            bgcolor: 'rgba(255,255,255)',
                          }}
                        />
                      )}
                      <Chip
                        label={media.type || 'Image'}
                        variant="outlined"
                        sx={{
                          top: 8,
                          left: 8,
                          bgcolor: 'rgba(255,255,255)',
                        }}
                      />
                    </div>

                    <IconButton onClick={(e) => handleMenuOpen(e, media)}>
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                  <CardMedia
                    component="img"
                    height="240"
                    image={media?.url}
                    alt={`Media ${index + 1}`}
                    sx={{ objectFit: 'cover' }}
                    className="p-4"
                  />
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedMedia === media}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={handleMakePrimary} disabled={media.is_primary}>
                      <CheckIcon fontSize="small" sx={{ marginRight: 1 }} />
                      Make Primary
                    </MenuItem>
                    <MenuItem onClick={handleDelete}>
                      <DeleteIcon fontSize="small" sx={{ marginRight: 1 }} />
                      Delete
                    </MenuItem>
                  </Menu>
                </Card>
              </article>
            ))}
          </section>

          <article className="row">
            <section className="mb-0 h-25 d-flex justify-content-end align-items-end">
              {isLoading ? (
                <button type="button" disabled className="btn btn-dark">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Saving changes
                </button>
              ) : (
                <button type="submit" className="btn btn-dark waves-effect waves-light">
                  Save changes
                </button>
              )}
            </section>
          </article>
        </form>
      </Container>
    </section>
  );
}

export default ItemMediaUpdate;
