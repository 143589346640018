import React, { useState } from 'react';
import { useEffect } from 'react';

import { OnPageLoaded } from '@link-loom/react-sdk';
import { VeripassStandardSignin } from '@veripass/react-sdk';

function SetUI() {
  const element = document.querySelector('body');
  element.classList.add('authentication-bg');
}

function AuthSigninPasswordPage() {
  useEffect(() => {
    SetUI();
  }, []);

  return (
    <>
      <VeripassStandardSignin
        organization={{
          logoSrc: '/assets/images/logo-dark-2.svg',
          slogan: 'Be one with your soulmate.',
        }}
        redirectUrl="/admin"
        debug={process.env.REACT_APP_VERIPASS_DEBUG}
        apiKey={process.env.REACT_APP_VERIPASS_API_KEY}
      />
      <OnPageLoaded />
    </>
  );
}

export default AuthSigninPasswordPage;
