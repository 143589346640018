import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner, SnapData } from '@link-loom/react-sdk';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material/';

const ItemSizePreview = ({ entity, setIsOpen, isPopupContext }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const confirmOnClick = async () => {
    setIsOpen(false);
    navigate(`/admin/users/management/${entity?.id}`);
  };

  const closeOnClick = async () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsLoading(false);
    console.log(entity);

    setRows([
      { title: 'height', value: entity?.height || '-' },
      { title: 'width', value: entity?.width || '-' },
      { title: 'depth', value: entity?.depth || '-' },
      { title: 'trade_measure', value: entity?.trade_measure || '-' },
      { title: 'girdle', value: entity?.girdle || '-' },
      { title: 'table', value: entity?.table || '-' },
      { title: 'crown', value: entity?.crown || '-' },
      { title: 'pavilion_depth', value: entity?.pavilion_depth || '-' },
      { title: 'pavilion_average_angle', value: entity?.pavilion_average_angle || '-' },
      { title: 'unit', value: entity?.unit || '-' },
    ]);
  }, [entity]);

  useEffect(() => {
    console.log('rows', rows);
  }, [rows]);

  useEffect(() => {
    console.log('ItemSizePreview component mounted');

    return () => {
      console.log('ItemSizePreview component unmounted');
    };
  }, []);

  return (
    <section className="card-body p-0">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.title}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" variant="head">
                      {row.title}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {isPopupContext && (
        <section className="d-flex justify-content-center mt-4 pb-3">
          <button
            title="Submit"
            type="submit"
            className="btn btn btn-white btn-action mx-2"
            onClick={closeOnClick}
          >
            <i className="bi bi-check"></i> Close
          </button>
          <button
            title="Edit user"
            type="submit"
            className="btn btn-soft-success btn-action mx-2"
            onClick={confirmOnClick}
          >
            <i className="bi bi-check"></i> Edit user
          </button>
        </section>
      )}
    </section>
  );
};

export default ItemSizePreview;
