import React, { useState, useEffect } from 'react';

import { FormGroup, FormControlLabel, Switch } from '@mui/material';
import { Alert, Spinner } from '@link-loom/react-sdk';

import { ItemTimelinePreview, ItemOverviewUpdate } from '@components/pages';

const defaultAlertConfigs = {
  success: {
    title: 'Success',
    description: 'Operation completed successfully, do you want to continue editing?',
    typeIcon: 'success',
    confirmButtonText: 'Yes, continue editing',
    cancelButtonText: 'No',
    cancelButtonClass: 'btn btn-white',
    showCancelButton: true,
    currentAction: 'updated',
  },
  error: {
    title: 'Error',
    description: 'Something was wrong while executing action.',
    typeIcon: 'error',
    confirmButtonText: 'Exit',
    cancelButtonText: 'Try again',
    cancelButtonClass: 'btn btn-white',
    showCancelButton: true,
    currentAction: 'updated',
  },
};

const ItemTimelineDetail = (props) => {
  const { entityState, entityRef, relatedEntities, loadingState } = props;
  const [isEditMode, setIsEditMode] = useState(false);
  const [showDialog, setShowDialog] = useState(null);
  const [dialogConfigs, setDialogConfigs] = useState(null);

  const dialogOnConfirmed = (isConfirmed) => {
    switch (dialogConfigs.currentAction) {
      case 'updated':
        setIsEditMode(isConfirmed);
        break;

      default:
        break;
    }

    setShowDialog(false);
  };

  useEffect(() => {
    if (entityState === false) {
      loadingState.setIsLoading(false);
    }
  }, [entityState]);

  return (
    <>
      <section className="content">
        {loadingState.isLoading ? (
          <Spinner />
        ) : (
          <>
            <ItemTimelinePreview entity={entityState?.entity} />
          </>
        )}
      </section>

      {showDialog === true && <Alert config={dialogConfigs} setConfirm={dialogOnConfirmed} />}
    </>
  );
};

export default ItemTimelineDetail;
