import React, { useEffect } from 'react';
import { useAuth, VeripassLogoutSuccess } from '@veripass/react-sdk';

function SetUI() {
  useEffect(() => {
    const element = document.querySelector('body');
    element.classList.add('authentication-bg');
  }, []);
}

function AuthLogoutPage() {
  const authProvider = useAuth();
  useEffect(() => {
    authProvider.logout();
  }, []);

  SetUI();

  return (
    <>
      <VeripassLogoutSuccess
        organization={{
          logoSrc: '/assets/images/logo-dark-2.svg',
        }}
      />
    </>
  );
}

export default AuthLogoutPage;
