import React, { useEffect } from 'react';
import { VeripassStandardUnlock } from '@veripass/react-sdk';

function SetUI() {
  const element = document.querySelector('body');
  element.classList.add('authentication-bg');
}

function AuthLockscreenPage() {
  useEffect(() => {
    SetUI();
  }, []);

  return (
    <>
      <VeripassStandardUnlock
        organization={{
          logoSrc: '/assets/images/logo-dark-2.svg',
          slogan: 'Be one with your soulmate.',
        }}
      />
    </>
  );
}

export default AuthLockscreenPage;
