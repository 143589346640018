import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@link-loom/react-sdk';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
} from '@mui/material/';

const ItemGemologicalCompositionPreview = ({ entity, setIsOpen, isPopupContext }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const confirmOnClick = async () => {
    setIsOpen(false);
    navigate(`/admin/users/management/${entity?.id}`);
  };

  const closeOnClick = async () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsLoading(false);

    console.log(entity);

    setRows([
      { title: 'Family', value: entity?.family || '-' },
      { title: 'Species', value: entity?.species || '-' },
      { title: 'Variety', value: entity?.variety || '-' },
      { title: 'Subvariety', value: entity?.subvariety || '-' },
      { title: 'Chemical Formula', value: entity?.chemical_formula || '-' },
      { title: 'Origin', value: entity?.origin || '-' },
      { title: 'Treatments', value: entity?.treatments || '-' },
      { title: 'Hardness (Mohs Scale)', value: entity?.hardness_mohs_scale || '-' },
      { title: 'Refractive Index', value: entity?.refractive_index || '-' },
      { title: 'Specific Gravity', value: entity?.specific_gravity || '-' },
      { title: 'Inclusions', value: entity?.inclusions || '-' },
      { title: 'Dispersion', value: entity?.dispersion || '-' },
      { title: 'Birefringence', value: entity?.birefringence || '-' },
      { title: 'Lab Grown', value: entity?.is_lab_grown ? 'Yes' : 'No' },
      { title: 'Fluorescence', value: entity?.fluorescence?.title || '-' },
      { title: 'Optical Effects', value: entity?.optical_effects?.title || '-' },
      { title: 'Luster', value: entity?.luster?.title || '-' },
      { title: 'Phenomena', value: entity?.phenomena?.title || '-' },
      { title: 'Crystal System', value: entity?.crystal_system?.title || '-' },
      { title: 'Tenacity', value: entity?.tenacity?.title || '-' },
      { title: 'Fracture', value: entity?.fracture?.title || '-' },
      { title: 'Cleavage', value: entity?.cleavage?.title || '-' },
      { title: 'Pleochroism', value: entity?.pleochroism?.title || '-' },
      { title: 'Color Zoning', value: entity?.color_zoning?.title || '-' },
      { title: 'Thermal Conductivity', value: entity?.thermal_conductivity?.title || '-' },
      { title: 'Phosphorescence', value: entity?.phosphorescence?.title || '-' },
      { title: 'Durability', value: entity?.durability?.title || '-' },
      { title: 'Specific Inclusions', value: entity?.specific_inclusions?.title || '-' },
      { title: 'Double Refraction Index', value: entity?.double_refraction_index?.title || '-' },
    ]);
  }, [entity]);

  return (
    <section className="card-body p-0">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="gemological composition table">
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.title}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row" variant="head">
                      {row.title}
                    </TableCell>
                    <TableCell align="right">{row.value}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {isPopupContext && (
        <section className="d-flex justify-content-center mt-4 pb-3">
          <button
            title="Submit"
            type="submit"
            className="btn btn btn-white btn-action mx-2"
            onClick={closeOnClick}
          >
            <i className="bi bi-check"></i> Close
          </button>
          <button
            title="Edit user"
            type="submit"
            className="btn btn-soft-success btn-action mx-2"
            onClick={confirmOnClick}
          >
            <i className="bi bi-check"></i> Edit user
          </button>
        </section>
      )}
    </section>
  );
};

export default ItemGemologicalCompositionPreview;
