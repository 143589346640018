import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InventoryManagementService } from '@services';
import { Alert } from '@link-loom/react-sdk';

const Loader = () => (
  <div className="d-flex justify-content-center">
    <div className="spinner-border m-2" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

function InventoryViewManagement() {
  const { id } = useParams();
  const [managementData, setManagementData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [statuses, setStatuses] = useState({});
  const [isConfirm, setIsConfirm] = useState(null);
  const [formFields, setFormFields] = useState({
    display_name: '',
    slug: '',
    logo_url: '',
    status: {},
  });
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      const entityService = new InventoryManagementService();
      const entityPromise = entityService.getByParameters({
        queryselector: 'id',
        search: id,
      });
      const entityStatusesPromise = entityService.getByParameters({
        queryselector: 'status',
        search: '',
      });

      try {
        const [entityResponse, entityStatuses] = await Promise.all([
          entityPromise,
          entityStatusesPromise,
        ]);

        if (!entityResponse || !entityResponse.result || !entityResponse.success) {
          setIsAlertVisible(true);
        }

        if (entityResponse && entityStatuses && !isAlertVisible) {
          setManagementData(entityResponse.result[0]);
          setStatuses(entityStatuses.result || {});
        }
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData().catch(console.error);
  }, [id]);

  useEffect(() => {
    if (managementData && Object.keys(managementData).length !== 0) {
      loadManagementData();
    }
  }, [managementData]);

  useEffect(() => {
    if (alertInfo.type === 'confirm' && isConfirm === true) {
      loadManagementData();
      setIsEditMode(false);
    }
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  const handleOperationError = () => {
    setAlertInfo({
      message: 'Something went wrong. Please try again.',
      type: 'error',
    });
  };

  const handleEditModeToggle = () => {
    if (!isEditMode) {
      setIsEditMode(true);
    } else {
      setAlertInfo({
        message: 'Are you sure you want to disable edit mode? Any unsaved changes will be lost.',
        type: 'confirm',
      });
    }
  };

  const handleSaveChanges = async () => {
    try {
      const updatedData = {
        id: id,
        display_name: formFields.display_name,
        slug: formFields.slug,
        logo_url: formFields.logo_url,
      };

      const entityService = new InventoryManagementService();

      if (formFields.status === statuses.deleted) {
        const updatePromise = entityService.update(updatedData);
        const deletePromise = entityService.delete({ id });

        await Promise.all([updatePromise, deletePromise]);

        if (!updatePromise || !deletePromise) {
          handleOperationError();
          return null;
        }
      } else {
        const entityResponse = await entityService.update({
          ...updatedData,
          status: formFields.status,
        });
        if (!entityResponse || !entityResponse.result) {
          handleOperationError();
          return null;
        }
      }

      setIsEditMode(false);
      setManagementData({ ...formFields });
      setAlertInfo({
        message: 'Changes have been saved successfully!',
        type: 'success',
      });
    } catch (error) {
      console.error(error);
      handleOperationError();
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormFields({ ...formFields, [id]: value });
  };

  const loadManagementData = () => {
    setFormFields({
      display_name: managementData.display_name || '',
      slug: managementData.slug || '',
      logo_url: managementData.logo_url || '',
      status: managementData.status || {},
    });
  };

  const handleStatusChange = (e) => {
    const selectedStatusName = e.target.value;
    const selectedStatus = Object.values(statuses).find(
      (status) => status.name === selectedStatusName,
    );
    setFormFields({ ...formFields, status: selectedStatus });
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'confirm':
        return {
          title: 'Confirm Action',
          description: alertInfo.message,
          typeIcon: 'question',
          confirmButtonText: 'Accept',
          cancelButtonText: 'Cancel',
          showCancelButton: true,
        };
      case 'success':
        return {
          title: 'Success',
          description: alertInfo.message,
          typeIcon: 'success',
          showCancelButton: false,
        };
      case 'error':
        return {
          title: 'Error',
          description: alertInfo.message,
          typeIcon: 'error',
          showCancelButton: false,
        };
      default:
        return null;
    }
  };

  const fields = [
    { name: 'Name', id: 'display_name' },
    { name: 'Slug', id: 'slug' },
    { name: 'Logo URL', id: 'logo_url' },
  ];

  return (
    <div className="card-body">
      {alertInfo.type && <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />}
      {isLoading ? (
        <Loader />
      ) : (
        <section>
          {isAlertVisible ? (
            <div className="alert alert-danger bg-secondary text-white border-0 w-50 d-flex justify-content-center mx-auto">
              <span className="mx-auto">
                No data <strong>available</strong> at the moment!
              </span>
            </div>
          ) : (
            <section>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="header-title">Manage the organization</h4>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="editModeSwitch"
                    checked={isEditMode}
                    onChange={handleEditModeToggle}
                  />
                  <label className="form-check-label" htmlFor="editModeSwitch">
                    Edit Mode
                  </label>
                </div>
              </div>

              <p className="sub-header">
                This form allows you to manage the organization details. You can update the name,
                slug, logo URL, and status of the organization.
              </p>

              <form>
                <div className="row">
                  {fields.map((field, key) => (
                    <div key={key} className={field.className || 'col-md-6 mb-3'}>
                      <label htmlFor={field.id} className="form-label">
                        {' '}
                        {field.name}{' '}
                      </label>
                      <input
                        type="text"
                        value={formFields[field.id] || ''}
                        id={field.id}
                        className="form-control"
                        readOnly={field.readOnly || !isEditMode}
                        onChange={handleChange}
                      />
                    </div>
                  ))}

                  <div className="col-md-6 mb-3">
                    <label htmlFor="statusSelect" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-select"
                      value={formFields.status.name}
                      id="statusSelect"
                      onChange={(e) => handleStatusChange(e)}
                      disabled={!isEditMode}
                    >
                      {statuses &&
                        Object.values(statuses).map((status) => (
                          <option key={status.id} value={status.name}>
                            {status.title}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </form>
            </section>
          )}
        </section>
      )}

      {isEditMode && (
        <div className="d-flex justify-content-end mt-3">
          <button className="btn btn-primary" onClick={handleSaveChanges}>
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
}

export default InventoryViewManagement;
