import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spinner } from '@link-loom/react-sdk';
import { Card, Chip, CardMedia } from '@mui/material';

const ItemMediaPreview = ({ entity, setIsOpen, isPopupContext }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [media, setMedia] = useState([]);

  const confirmOnClick = async () => {
    setIsOpen(false);
    navigate(`/admin/users/management/${entity?.id}`);
  };

  const closeOnClick = async () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsLoading(false);

    setMedia(entity?.media);
  }, [entity]);

  return (
    <section className="card-body p-0">
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <section className="row">
            {media?.map((media, index) => (
              <div className="col-3 mb-4" key={index}>
                <Card sx={{ position: 'relative' }}>
                  <div className="d-flex justify-content-between align-items-center p-2 w-100">
                    <div>
                      {media.is_primary && (
                        <Chip
                          label="Search Cover"
                          variant="outlined"
                          className="me-1"
                          sx={{
                            top: 8,
                            left: 8,
                            bgcolor: 'rgba(255,255,255)',
                          }}
                        />
                      )}
                      <Chip
                        label={media.type || 'Image'}
                        variant="outlined"
                        sx={{
                          top: 8,
                          left: 8,
                          bgcolor: 'rgba(255,255,255)',
                        }}
                      />
                    </div>
                  </div>
                  <CardMedia
                    component="img"
                    height="240"
                    image={media.url}
                    alt={`Media ${index + 1}`}
                    sx={{ objectFit: 'cover' }}
                    className="p-4"
                  />
                </Card>
              </div>
            ))}
          </section>
        </>
      )}
      {isPopupContext && (
        <section className="d-flex justify-content-center mt-4 pb-3">
          <button
            title="Submit"
            type="submit"
            className="btn btn-white btn-action mx-2"
            onClick={closeOnClick}
          >
            <i className="bi bi-check"></i> Close
          </button>
          <button
            title="Edit user"
            type="submit"
            className="btn btn-soft-success btn-action mx-2"
            onClick={confirmOnClick}
          >
            <i className="bi bi-check"></i> Edit user
          </button>
        </section>
      )}
    </section>
  );
};

export default ItemMediaPreview;
