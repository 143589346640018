import React, { useEffect } from 'react';
import { VeripassNewPassword } from '@veripass/react-sdk';

function SetUI() {
  const element = document.querySelector('body');
  element.classList.add('authentication-bg');
}

function AuthNewPasswordPage() {
  useEffect(() => {
    SetUI();
  }, []);

  return (
    <>
      <VeripassNewPassword
        organization={{
          logoSrc: '/assets/images/logo-dark-2.svg',
          slogan: 'Be one with your soulmate.',
        }}
      />
    </>
  );
}

export default AuthNewPasswordPage;
