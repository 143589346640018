import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InventoryItemService } from '@services';
import { Alert } from '@link-loom/react-sdk';

const Loader = () => (
  <div className="d-flex justify-content-center">
    <div className="spinner-border m-2" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

function InventoryViewInformation() {
  const { id } = useParams();
  const [managementData, setManagementData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isConfirm, setIsConfirm] = useState(null);
  const [formFields, setFormFields] = useState({
    id: '',
    organization_id: '',
    description: '',
    address: '',
    phone_number: '',
    contact_email: '',
    website_url: '',
    industry: '',
    size: '',
    founded_year: '',
    parent_organization_id: '',
    is_verified: '',
    headquarters_country: '',
    headquarters_state: '',
    headquarters_city: '',
    headquarters_zip_code: '',
  });
  const [alertInfo, setAlertInfo] = useState({
    message: '',
    type: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      const entityService = new InventoryItemService();
      const entityResponse = await entityService.getByParameters({
        queryselector: 'organization-id',
        search: id,
      });

      if (!entityResponse || !entityResponse.result.length || !entityResponse.success) {
        setIsAlertVisible(true);
      }

      if (entityResponse && !isAlertVisible) {
        setManagementData(entityResponse.result[0]);
      }
      setIsLoading(false);
    };

    fetchData().catch(console.error);
  }, [id]);

  useEffect(() => {
    if (managementData && Object.keys(managementData).length !== 0) {
      loadManagementData();
    }
  }, [managementData]);

  useEffect(() => {
    if (alertInfo.type === 'confirm' && isConfirm === true) {
      loadManagementData();
      setIsEditMode(false);
    }
    setAlertInfo({ ...alertInfo, type: null });
    setIsConfirm(null);
  }, [isConfirm]);

  const handleEditModeToggle = () => {
    if (!isEditMode) {
      setIsEditMode(true);
    } else {
      setAlertInfo({
        message: 'Are you sure you want to disable edit mode? Any unsaved changes will be lost.',
        type: 'confirm',
      });
    }
  };

  const handleSaveChanges = async () => {
    try {
      const updatedData = {
        id: formFields.id,
        organization_id: id,
        description: formFields.description,
        address: formFields.address,
        phone_number: formFields.phone_number,
        contact_email: formFields.contact_email,
        website_url: formFields.website_url,
        industry: formFields.industry,
        size: formFields.size,
        founded_year: formFields.founded_year,
        parent_organization_id: formFields.parent_organization_id,
        is_verified: formFields.is_verified,
        headquarters_country: formFields.headquarters_country,
        headquarters_state: formFields.headquarters_state,
        headquarters_city: formFields.headquarters_city,
        headquarters_zip_code: formFields.headquarters_zip_code,
      };

      const entityService = new InventoryItemService();
      const entityResponse = await entityService.update(updatedData);
      if (!entityResponse || !entityResponse.result) {
        setAlertInfo({
          message: 'Something went wrong. Please try again.',
          type: 'error',
        });
        return null;
      }
      setIsEditMode(false);
      setManagementData({ ...formFields });
      setAlertInfo({
        message: 'Changes have been saved successfully!',
        type: 'success',
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormFields({ ...formFields, [id]: value });
  };

  const loadManagementData = () => {
    setFormFields({
      id: managementData.id || '',
      organization_id: managementData.organization_id || '',
      description: managementData.description || '',
      address: managementData.address || '',
      phone_number: managementData.phone_number || '',
      contact_email: managementData.contact_email || '',
      website_url: managementData.website_url || '',
      industry: managementData.industry || '',
      size: managementData.size || '',
      founded_year: managementData.founded_year || '',
      parent_organization_id: managementData.parent_organization_id || '',
      is_verified: managementData.is_verified || '',
      headquarters_country: managementData.headquarters_country || '',
      headquarters_state: managementData.headquarters_state || '',
      headquarters_city: managementData.headquarters_city || '',
      headquarters_zip_code: managementData.headquarters_zip_code || '',
    });
  };

  const getAlertConfig = () => {
    switch (alertInfo.type) {
      case 'confirm':
        return {
          title: 'Confirm Action',
          description: alertInfo.message,
          typeIcon: 'question',
          confirmButtonText: 'Accept',
          cancelButtonText: 'Cancel',
          showCancelButton: true,
        };
      case 'success':
        return {
          title: 'Success',
          description: alertInfo.message,
          typeIcon: 'success',
          showCancelButton: false,
        };
      case 'error':
        return {
          title: 'Error',
          description: alertInfo.message,
          typeIcon: 'error',
          showCancelButton: false,
        };
      default:
        return null;
    }
  };

  const fields = [
    { name: 'Inventory ID', id: 'organization_id', readOnly: true },
    { name: 'Phone Number', id: 'phone_number' },
    { name: 'Contact Email', id: 'contact_email' },
    { name: 'Website URL', id: 'website_url' },
    { name: 'Description', id: 'description', className: 'mb-3' },
    { name: 'Address', id: 'address' },
    { name: 'Industry', id: 'industry' },
    { name: 'Size', id: 'size' },
    { name: 'Founded Year', id: 'founded_year' },
    { name: 'Parent Inventory ID', id: 'parent_organization_id' },
    { name: 'Is Verified', id: 'is_verified' },
    { name: 'Headquarters Country', id: 'headquarters_country' },
    { name: 'Headquarters State', id: 'headquarters_state' },
    { name: 'Headquarters City', id: 'headquarters_city' },
    { name: 'Headquarters Zip Code', id: 'headquarters_zip_code' },
  ];

  return (
    <div className="card-body">
      {alertInfo.type && <Alert config={getAlertConfig()} setConfirm={setIsConfirm} />}
      {isLoading ? (
        <Loader />
      ) : (
        <section>
          {isAlertVisible ? (
            <div className="alert alert-danger bg-secondary text-white border-0 w-50 d-flex justify-content-center mx-auto">
              <span className="mx-auto">
                No data <strong>available</strong> at the moment!
              </span>
            </div>
          ) : (
            <section>
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="header-title">Manage the organization information</h4>
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="editModeSwitch"
                    checked={isEditMode}
                    onChange={handleEditModeToggle}
                  />
                  <label className="form-check-label" htmlFor="editModeSwitch">
                    Edit Mode
                  </label>
                </div>
              </div>

              <p className="sub-header">
                This form allows you to manage the organization's information. You can update
                various details such as the organization ID, description, address, phone number,
                contact email, website URL, industry, size, founded year, parent organization ID,
                verification status, headquarters location, and more.
              </p>

              <form>
                <div className="row">
                  {fields.map((field, key) => (
                    <div key={key} className={field.className || 'col-md-6 mb-3'}>
                      <label htmlFor={field.id} className="form-label">
                        {' '}
                        {field.name}{' '}
                      </label>
                      <input
                        type="text"
                        value={formFields[field.id] || ''}
                        id={field.id}
                        className="form-control"
                        readOnly={field.readOnly || !isEditMode}
                        onChange={handleChange}
                      />
                    </div>
                  ))}
                </div>
              </form>

              {isEditMode && (
                <div className="d-flex justify-content-end mt-3">
                  <button className="btn btn-primary" onClick={handleSaveChanges}>
                    Save Changes
                  </button>
                </div>
              )}
            </section>
          )}
        </section>
      )}
    </div>
  );
}

export default InventoryViewInformation;
