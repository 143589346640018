import React, { useEffect } from 'react';
import { VeripassStandardRecoverPassword } from '@veripass/react-sdk';

function SetUI() {
  const element = document.querySelector('body');
  element.classList.add('authentication-bg');
}

function AuthRecoverPasswordPage() {
  useEffect(() => {
    SetUI();
  }, []);

  return (
    <>
      <VeripassStandardRecoverPassword
        organization={{
          logoSrc: '/assets/images/logo-dark-2.svg',
          slogan: 'Be one with your soulmate.',
        }}
      />
    </>
  );
}

export default AuthRecoverPasswordPage;
