import BaseApi from '../base/api.service';

export default class InventoryManagementService extends BaseApi {
  constructor(args) {
    super(args);

    this.serviceEndpoints = {
      baseUrl: process.env.REACT_APP_BACKEND_URL,
      get: '/inventory/management/',
      create: '/inventory/management',
      update: '/inventory/management',
      delete: '/inventory/management',
    };
  }

  async getByParameters(data) {
    return super.getByParameters(data);
  }

  async update(data) {
    return super.update(data);
  }

  async create(data) {
    return super.create(data);
  }

  async delete(data) {
    return super.delete(data);
  }
}
